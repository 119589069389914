import React, { FC, InputHTMLAttributes } from 'react';
import { InputProps } from './Input.props';
import { cn } from 'utils';
import { useField } from 'formik';
import Text from '../Text';

const InputView: FC<InputProps> = ({
  label,
  className,
  labelContainer,
  inputContainer,
  name,
  value,
  ...inputProps
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className={cn(`flex flex-col gap-2 ${className}`)}>
      <div className={labelContainer}>
        <label htmlFor={inputProps.id}>{label}</label>
      </div>
      <div className={cn(`flex flex-col ${inputContainer}`)}>
        <input
          value={field?.value || value || ''}
          name={name}
          {...inputProps}
          className={cn(
            `input input-bordered w-full max-w-xs ${inputContainer}`,
          )}
          onChange={(e) => {
            helpers.setValue(e.target?.value || '');
            const value = e.target?.value || '';
            if (inputProps.onChange) inputProps.onChange(value);
          }}
        />
        {meta.error && meta.touched && (
          <Text className="text-red-500">{meta.error}</Text>
        )}
      </div>
    </div>
  );
};

export default InputView;
