import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ClientData } from 'types/client';

type State = {
  clientData: {
    _id: string;
    name: string;
    code: string;
    logo: string;
  } | null;
};
const useSessionStore = create(
  persist(
    immer<State>(() => ({
      clientData: null,
    })),
    { name: 'client-data-store' },
  ),
);

const selectors = {
  useClientData: () => useSessionStore((state) => state.clientData),
};

const actions = {
  set: useSessionStore.setState,
  setClientData: (clientData: ClientData) => {
    useSessionStore.setState((state) => {
      state.clientData = {
        _id: clientData._id,
        name: clientData.name,
        code: clientData.code,
        logo: clientData.logo,
      };
    });
  },
  getLogo: () => useSessionStore((state) => state.clientData?.logo),
  getName: () => useSessionStore((state) => state.clientData?.name),
};

/**
 * **Session Store**
 *
 * this store is used to store session tokens
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $clientStore = {
  actions,
  selectors,
} as const;

export default $clientStore;
